// src/components/Home.js
import React, { useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import config from '../config';

const Home = () => {
  const { user, logout } = useContext(UserContext);
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    document.title = "Plateforme E-learning";

    const fetchCourses = async () => {
      try {
        const response = await fetch(`${config.apiBaseUrl}/courses`);
        if (!response.ok) {
          throw new Error('Failed to fetch courses');
        }
        const data = await response.json();

        // Sort courses by "Matiere"
        const sortedCourses = data.sort((a, b) =>
          a.matiere.localeCompare(b.matiere, 'fr', { sensitivity: 'base' })
        );

        setCourses(sortedCourses);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, []);

  // Group courses by "niveau"
  const groupedCourses = courses.reduce((acc, course) => {
    if (!acc[course.niveau]) {
      acc[course.niveau] = [];
    }
    acc[course.niveau].push(course);
    return acc;
  }, {});

  // Define the custom order for levels
  const customLevelOrder = ["CM1", "CM2", "6e", "5e", "4e", "3e"];

  // Sort the grouped levels by the custom order
  const sortedLevels = Object.keys(groupedCourses).sort((a, b) => {
    const indexA = customLevelOrder.indexOf(a);
    const indexB = customLevelOrder.indexOf(b);

    // If both levels are in the custom order, sort by the order
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    // If only one level is in the custom order, it comes first
    if (indexA !== -1) return -1;
    if (indexB !== -1) return 1;

    // If neither is in the custom order, sort alphabetically
    return a.localeCompare(b, 'fr', { sensitivity: 'base' });
  });

  return (
    <main>

      <h1>Oubii Digital Learning</h1>
      <p>Cette plateforme de elearning est développée par Bakhtech et Oubii Digital.</p>
      <p>
        À la différence d'autres plateformes de elearning, celle-ci est pensée et optimisée pour un usage par
        des élèves aveugles ou malvoyants.
      </p>
      <p>
        Cette plateforme est utilisée dans le cadre de notre programme d'inclusion par le digital développé
        en partenariat avec l'INEFJA
      </p>

      <h2>Accès au cours</h2>
      {user && (user.type === "formateur" || user.type === "admin") &&
        <p><Link to="/mes-cours">Mes cours</Link></p>
      }

      <p><Link to="/cours">Tous les cours</Link></p>

      <p><Link to="/CODLesson">Cours Démo - Le Complément d'Objet Direct</Link></p>

      <h2>Cours en informatique adapté</h2>
      <p><Link to="/composants-web">Comprendre les composants web</Link></p>
      <p><Link to="/maitrise-clavier">Maîtrise du clavier pour aveugle : méthode Oubii Digital</Link></p>
      <p><Link to="/form">Remplir un formulaire avec son lecteur d'écran (NVDA)</Link></p>
      

      <h2>Cours par classe</h2>

      {/* Dynamically render sections for each level */}
      {loading ? (
        <div>Chargement des cours...</div>
      ) : error ? (
        <p className="text-danger">Erreur : {error}</p>
      ) : (
        sortedLevels.map((niveau) => (
          <div key={niveau}>
            <h3>{niveau}</h3>
            {groupedCourses[niveau]?.length === 0 ? (
              <p>Aucun cours disponible pour le niveau {niveau}.</p>
            ) : (
              <div className="table-responsive">
                <table className="table table-bordered table-striped">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Titre</th>
                      <th scope="col">Matière</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupedCourses[niveau].map(course => (
                      <tr key={course.id_cours}>
                        <td><Link to={`/cours/${course.id_cours}`}>{course.titre}</Link></td>
                        <td>{course.matiere}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        ))
      )}

      

      
    </main>
  );
};

export default Home;
