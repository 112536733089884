import React, { useEffect, useState, useRef } from 'react';

const MaitriseClavierLesson = () => {
    const [typedKey, setTypedKey] = useState('');
    const [lastKey, setLastKey] = useState('');
    const [tabCount, setTabCount] = useState(0);
    //const [pendingControl, setPendingControl] = useState(false);
    const controlTimeout = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        document.title = "Maîtrise du clavier pour aveugle : méthode Oubii Digital";
    }, []);

    const speak = (text) => {
        const utterance = new SpeechSynthesisUtterance(text);
        utterance.lang = 'fr-FR';
        speechSynthesis.speak(utterance);
    };


    let pendingControl = false

    const handleKeyDown = (event) => {
        event.preventDefault();

        let keyName = event.key;

        // Gestion spéciale de Control et AltGraph
        if (event.key === "Control") {
            pendingControl = true
            if (controlTimeout.current) clearTimeout(controlTimeout.current);
            console.log("ctrl pressed")
            controlTimeout.current = setTimeout(() => {
                console.log("ctrl pressed end timeout", pendingControl)
                if (pendingControl) {
                    setKey(keyName)
                }
                pendingControl = false //setPendingControl(false);
            }, 50); // Délai court pour voir si AltGraph arrive
            return;

        }

        if (event.key === "AltGraph" && pendingControl) {
            console.log("altgr pressed")
            pendingControl = false //setPendingControl(false);
        }

        if (event.key === 'Tab') {
            setTabCount(prev => prev + 1);
            setTimeout(() => setTabCount(0), 300);

            if (event.shiftKey) {
                inputRef.current.blur();
                document.activeElement?.previousElementSibling?.focus();
                return;
            } else if (tabCount === 1) {
                inputRef.current.blur();
                document.activeElement?.nextElementSibling?.focus();
                return;
            }
        }

        setKey(keyName)

        
    };

    const handleKeyUp = () => {
        setLastKey('');
    };

    function setKey(keyName){
        const specialKeys = {
            ' ': 'Espace',
            'Enter': 'Entrée',
            'Escape': 'Échap',
            'Backspace': 'Retour en arrière',
            'Tab': 'Tabulation',
            'Shift': 'Majuscule',
            'Meta': 'Windows',
            'Delete': 'Supprimer',
            'AltGraph': 'AltGr',
            'Control': 'Contrôle',
            'ArrowUp': 'Flèche vers le haut',
            'ArrowDown': 'Flèche vers le bas',
            'ArrowLeft': 'Flèche vers la gauche',
            'ArrowRight': 'Flèche vers la droite',
            'MediaPlayPause': "Lecture/Mettre en Pause",
            'MediaStop': 'Stop',
            'MediaTrackPrevious': "Aller à la piste précédente",
            'MediaTrackNext': "Aller à la piste suivante",
            'Alt': 'Alt',

            // Touches F1-F12
            'F1': 'F1', 'F2': 'F2', 'F3': 'F3', 'F4': 'F4',
            'F5': 'F5', 'F6': 'F6', 'F7': 'F7', 'F8': 'F8',
            'F9': 'F9', 'F10': 'F10', 'F11': 'F11', 'F12': 'F12',

            // Ponctuation courante
            /*'.': 'Point', ',': 'Virgule', ';': 'Point-virgule', ':': 'Deux-points',
            '?': 'Point d’interrogation', '!': 'Point d’exclamation', "'": 'Apostrophe', '"': 'Guillemet',*/

            // Parenthèses et crochets
           /*'(': 'Parenthèse gauche', ')': 'Parenthèse droite',
            '[': 'Crochet gauche', ']': 'Crochet droit',
            '{': 'Accolade gauche', '}': 'Accolade droite',*/

            // Symboles mathématiques et opérateurs
           /*  '+': 'Plus', '-': 'Moins', '*': 'Multiplication', '/': 'Division',
            '=': 'Égal', '%': 'Pourcentage',

            // Autres caractères spéciaux
           '@': 'Arobase', '#': 'Dièse', '$': 'Dollar',
            '&': 'Esperluette', '_': 'Tiret bas'*/
        };

       

        if (specialKeys[keyName]) {
            keyName = specialKeys[keyName];
            if (lastKey === keyName) {
                return;
            } else {
                speak(keyName);
            }
        }

        setTypedKey(keyName);
        setLastKey(keyName);
    }

    return (
        <div>
            <h1>Maîtrise du clavier pour aveugle : méthode Oubii Digital</h1>

            <h2>Les commandes et touches principales</h2>

            <h3>Échap</h3>
            <p>Située en haut à gauche du clavier, la touche Échap permet souvent d'annuler une action ou de fermer une fenêtre.</p>

            <h3>Entrée</h3>
            <p>Située en bas à droite du clavier principal, la touche Entrée valide une action ou passe à une nouvelle ligne dans un éditeur de texte.</p>

            <h3>F et J</h3>
            <p>Les touches F et J sont reconnaissables grâce à un petit repère en relief (bosses) qui permettent de positionner correctement ses mains sur le clavier.</p>

            <h3>Espace</h3>
            <p>La barre d'espace est la touche la plus large du clavier, située en bas au centre.</p>

            <h3>Alt</h3>
            <p>Située de chaque côté de la barre d'espace, la touche Alt permet d'activer des raccourcis clavier en combinaison avec d'autres touches. Celle de droite "AltGr" est l'équivalent des touches Alt et Ctrl préssés en même temps.</p>

            <h3>Ctrl</h3>
            <p>Située tout en bas à gauche et en bas à droite du clavier, la touche Ctrl (Contrôle) est utilisée avec d'autres touches pour exécuter des commandes rapides (ex: Ctrl + C pour copier).</p>

            <h3>Effacer (Retour en arrière et Suppr)</h3>
            <p>La touche Retour en arrière est située en haut à droite du clavier principal et permet de supprimer un caractère en arrière.</p>
            <p>La touche Suppr (Supprimer) est souvent située près du pavé numérique et permet de supprimer un caractère devant le curseur.</p>

            <h3>Shift</h3>
            <p>Les touches Shift (Majuscule) sont situées de chaque côté du clavier, généralement juste au-dessus des touches Ctrl, et permettent d'écrire des lettres majuscules ou d'accéder aux symboles secondaires des touches.</p>

            <h2>Pratique</h2>

            <label htmlFor="keyboard-input">
                Essayez d'appuyer sur une touche pour entendre son nom. Appuyer 2 fois de suite sur tab pour sortir du champ.
            </label>
            <input
                ref={inputRef}
                id="keyboard-input"
                type="text"
                value={typedKey}
                readOnly
                aria-live='polite'
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyUp}
                placeholder="Appuyez sur une touche..."
                style={{ width: '100%', padding: '10px', fontSize: '1.2em' }}
            />
        </div>
    );
};

export default MaitriseClavierLesson;
